<template>
    <form
        class="login__form__content"
        @submit="checkForm"
        novalidate="true">
        <h2 class="login__form__title" >¡Hola de nuevo!</h2>
        <p class="login__form__subtitle">Inicia sesión para acceder a tu cuenta</p>

        <v-alert type="error" v-if="errors.length">
            <b>Por favor, corrija los siguientes errores:</b>
            <ul class="pl-0">
                <li v-for="error in errors" :key="error">
                    {{ error }}
                </li>
            </ul>
        </v-alert>

        <input
            type="email"
            class="login__form__input"
            placeholder="Correo electrónico"
            v-model="email"
        />
        <input
            class="login__form__input"
            placeholder="Contraseña"
            :type="passwordFieldType"
            v-model="password"
        />
        <span
            class="login__form__input--icon material-icons-outlined"
            type="password" @click="switchVisibility">
            {{passEyeClass}}
        </span>
        <a
            href="#"
            v-bind:style="{ color: textPrimary }"
            class="login__form__link">
            <span class="element--block">¿Olvidaste la contraseña?</span>
        </a>

        <button
            type="submit"
            class="login__form__button"
            v-bind:style="{ backgroundColor: textSecundary }">
            Iniciar sesión
        </button>

        <a
            href="#"
            v-bind:style="{ color: textPrimary }"
            class="login__form__link">
            <span class="element--block">¿No tienes una cuenta aún?</span>
            <b class="element--block"><u>Regístrate</u></b>
        </a>
    </form>
</template>


<script>

import info from '../config/whitemarkInfo'

export default {
    name: 'LoginForm',
    data: () => ({
        password: "",
        email: "",
        errors: [],
        passEyeClass: "visibility_off",
        passwordFieldType: "password",
        info,
        textPrimary: info.corporateColors.primary,
        textSecundary: info.corporateColors.secundary,
        data:{
          attributes:{
            email: '',
            rut: '',
            password: '',
            company: ''
          }
        }
    }),
    methods: {
        switchVisibility(){
            this.passEyeClass = this.passwordFieldType === "password" ? "visibility" : "visibility_off"
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        checkForm: function (e){
            var vm = this;
            e.preventDefault();

            this.errors = [];

            if(!this.email){
                this.errors.push('El correo electrónico es obligatorio.');
            }

            if(!this.password){
                this.errors.push('La contraseña es obligatoria.');
            }

            this.data.attributes.email = this.email
            this.data.attributes.password = this.password

            navigator.geolocation.getCurrentPosition(vm.findPosition);

            try{
              console.log("Device ID");
              console.log(this.buildDeviceId());
              console.log(this.data);
              console.log(this.encrypt(this.data).toString());
              if(this.data.attributes.password != "" &&
                (this.data.attributes.email != "" || this.data.attributes.rut != "") ){
                this.$http.post("users/sign_in", {
                  data: this.encrypt(this.data).toString()
                }, {
                  headers: {
                    "X-Device-ID" : this.buildDeviceId(),
                    "Geolocation" : "lat: " + vm.getLatitude() + ", long: " + vm.getLongitude()
                  }
                }).then(function(response){
                    // this.updateBookingAccess(null)
                    if(response.body.meta != undefined && response.body.meta != null){
                      if(response.body.meta.uuid != undefined &&
                        response.body.meta.uuid != null){
                        this.updateUserIdEncrypt(response.body.meta.uuid);
                      }
                      if(response.body.meta.authentication_token != undefined &&
                        response.body.meta.authentication_token != null){
                        this.checkToken(response.body.meta.authentication_token)
                        // this.deleteSession('home', false);
                        // this.create_user_db(this, this.getDB(), response);
                      }
                      console.log('informacion usuario logeado===', response.body)
                      // console.log('email cliente logeado===', response.body.data.attributes.email)
                      // console.log('informacion cliente logeado===', response.body.included[0].attributes)
                      // console.log('id usuario logeado===', response.body.data.id)

                      console.log('este es el token===', response.body.meta.authentication_token)

                      localStorage.setItem('infoClient', JSON.stringify(response.body.included[0].attributes));
                      localStorage.setItem('userId', response.body.data.id);
                      localStorage.setItem('emailClient', response.body.data.attributes.email);
                      localStorage.setItem('authenticationToken', response.body.meta.authentication_token);

                      this.updateUserInfoId(response.body.included[0].id);
                      /* this.updateUserRut(response.body.data.attributes.rut); */
                      this.updateUserId(response.body.data.id);
                      console.log('response sing in info!!!:',response.body.data.attributes.update_data)
                      this.$router.push({name: 'Offers'})
                    }
                }, function(response){
                  if(response.body.meta != undefined && response.body.meta != null){
                    if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                      this.checkToken(response.body.meta.authentication_token)
                    }
                  }
                  this.show_error_modal(response.body.errors[0].details);
                });
              }else{
                this.show_error_modal();
              }
            }catch(e){
              this.show_error_modal(e.message);
            }

        }

    },
    mounted(){
      this.data.attributes.company = this.getCurrentCompany()
      if (localStorage.getItem("authenticationToken")) {
        this.checkToken(localStorage.getItem("authenticationToken"));
        this.updateUserInfoId(
          JSON.parse(localStorage.getItem("infoClient")).id
        );
        this.updateUserId(localStorage.getItem("userId"));
        this.$router.push({ name: "Offers" });
      }
    }

}
</script>
