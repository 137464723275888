<template>
  <div class="login">
        <div class="login__head__phone">
            <img :src="Logo" class="login__head--imagen">
        </div>
    <div 
        class="column-6 login__left_banner" 
        v-bind:style="{ backgroundImage: 'url('+Banner+')' }"
    >
        <div class="login__content">

            <div class="login__head">
                <center>
                    <img :src="Logo" class="login__head--imagen">
                </center>
            </div>

            <div class="login__body">
                <h1 class="login__body__title">{{info.infomercial.name}}</h1>
                <p class="login__body__description">{{info.infomercial.description}}</p>
            </div>

        </div>        
    </div>
    <div class="column-6">
        <div class="login__form" v-bind:style="{ color: textPrimary }">
            <login-form></login-form>
        </div>
    </div>
  </div>
</template>

<script>

import info from '../config/whitemarkInfo'
import Logo from '../assets/img/logo.png'
import Banner from '../assets/img/Franja.png'

import loginForm from './LoginForm.vue'

export default {
  name: 'Login',
  components: {
    loginForm
  },
  data() {
    return {
        info,
        Logo,
        textPrimary: info.corporateColors.primary,
        Banner
    }
  }
}
</script>


