const info = {
    name: 'Fusepong',
    banner: "http://localhost:8080/img/Franja.3b10e08b.png",
    logo: {
        url: 'http://localhost:8080/img/logo.7db17bcb.png',
        whiteVersionUrl: ''
    },
    infomercial: {
        name: '¡Con nosotros puedes estar tranquilo!',
        description: 'En nuestras clínicas hemos reforzado todos los protocolos necesarios para que cuando nos visites te sientas cómodo y seguro'
    },
    corporateColors: {
        primary: '#1C3B72',
        secundary: '#86BA43',
        tertiary: '#86BA43',
    },
    contactInfo: {
        email: 'comercial@fusepong.com'
    }
}

export default info;